@import "font.css";
html,
body {
  background: #101010 !important;
  background-repeat: repeat-y;
  padding: 0;
  margin: 0;
  font-family: Neo Sans Pro, sans-serif !important;
}

a {
  color: #f85a2a !important;
}

a:hover {
  color: #d63808 !important;
}
