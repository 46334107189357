.user-modal {
  background-color: transparent;
}

.modal-content {
  top: 50px;
  border-radius: 20px;
  background-position: center;
  scrollbar-width: none !important;
  border: none !important;
  max-width: 500px;
  padding: 5px;
}
.modal-backdrop {
  background: #1f1736;
  opacity: 0.9 !important;
}
///////////////

@import url("https://fonts.googleapis.com/css?family=Raleway:400,700");

.container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.screen {
  background: linear-gradient(90deg, #5d54a4, #7c78b8);
  position: relative;
  box-shadow: 0px 0px 24px #5c5696;
}

.content {
  z-index: 1;
  position: relative;
  height: 100%;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  -webkit-clip-path: inset(0 0 0 0);
  clip-path: inset(0 0 0 0);
}

.shape {
  transform: rotate(45deg);
  position: absolute;
}

.shape1 {
  height: 520px;
  width: 520px;
  background: #fff;
  top: -50px;
  right: 120px;
  border-radius: 0 72px 0 0;
}

.shape2 {
  height: 220px;
  width: 220px;
  background: #ac6c63;
  top: -172px;
  right: 0;
  border-radius: 32px;
}

.shape3 {
  height: 540px;
  width: 190px;
  background: linear-gradient(270deg, #548fa4, #aa0f8b);
  top: -24px;
  right: 0;
  border-radius: 32px;
}

.shape4 {
  height: 400px;
  width: 200px;
  background: #7e7bb9;
  top: 420px;
  right: 50px;
  border-radius: 60px;
}

.login {
  width: 320px;
  padding: 30px;
  padding-top: 56px;
}

.field {
  padding: 20px 0px;
  position: relative;
}

.icon {
  position: absolute;
  top: 30px;
  color: #7875b5;
}

.input {
  border: none;
  border-bottom: 2px solid #d1d1d4;
  background: none;
  padding: 10px;
  padding-left: 24px;
  font-weight: 700;
  transition: 0.2s;
  &:active,
  &:focus,
  &:hover {
    outline: none;
    border-bottom-color: #6a679e;
  }
}

.submit {
  &:hover {
    background: rgb(173, 49, 8);
    color: #fff;
  }
  &:active {
    scale: 0.95;
  }
  background: #fff;
  font-size: 14px;
  margin-top: 30px;
  padding: 16px 20px;
  border-radius: 26px;
  border: 1px solid #d4d3e8;
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  align-items: center;
  width: 100%;
  color: #4c489d;
  box-shadow: 0px 2px 2px #5c5696;
  cursor: pointer;
  transition: 0.2s;
  justify-content: center;
}

.submit:active,
.submit:focus,
.submit:hover {
  border-color: #6a679e;
  outline: none;
}

.button__icon {
  font-size: 24px;
  margin-left: auto;
  color: #7875b5;
}

.social-login {
  position: absolute;
  height: 140px;
  width: 160px;
  text-align: center;
  bottom: 0px;
  right: 0px;
  color: #fff;
}

.social-icons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-icon {
  padding: 20px 10px;
  color: #fff;
  text-decoration: none;
  text-shadow: 0px 0px 8px #7875b5;
}

.social-icon:hover {
  transform: scale(1.5);
}

.success {
  color: #4d4d4d;
  font-size: 20px;
  font-weight: 700;
  padding: 100px 50px;
  text-align: center;
}
