@import "swiper/scss";

@import "swiper/scss/pagination";
@import "swiper/css/navigation";
@import "swiper/css/grid";
@import "swiper/css/free-mode";

.swiper-container {
  overflow: visible;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}
.swiper-pagination {
  position: absolute;
  bottom: 0px !important;
  left: 0;
  right: 0;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  flex-flow: column;
  z-index: 9;
}

.swiper-pagination-bullet {
  background: #ffffff;
  opacity: 0.2;
}

.swiper-pagination-bullet-active {
  background: #ff3b00;
  opacity: 1;
}

.swiper-pagination {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  flex-flow: column;
  z-index: 9;
}
.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: opacity 0.3s;
  transform: translateZ(0);
  z-index: 10;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: 10px;
  left: auto;
}
.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;
  width: calc(44px / 44 * 27);
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: 44px;
  height: var(--swiper-navigation-size);
  margin-top: calc(44px * -1 / 2);
  margin-top: calc(var(--swiper-navigation-size) * -1 / 2);
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--swiper-theme-color);
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
}
.swiper-button-next {
  right: -16px;
}
.swiper-button-next {
  right: -16px;
}
.swiper-button-next,
.swiper-button-next,
.swiper-button.swiper-button-next {
  right: 20px;
  transform: rotate(180deg);
}
.swiper-button,
.swiper-button-next,
.swiper-button-next,
.swiper-button-prev {
  width: 32px;
  height: 32px;
}

.swiper-button-next:after,
.swiper-button-prev:after,
.swiper-button:after {
  border-radius: 0;
  border: none;
}

.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  content: "next";
}
.swiper-button-prev,
.swiper-button-next {
  display: none;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-family: swiper-icons;
  font-size: 44px;
  font-size: var(--swiper-navigation-size);
  text-transform: none !important;
  letter-spacing: 0;
  text-transform: none;
  font-variant: normal;
  line-height: 1;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  content: " " !important;
}

.swiper-button-next:after,
.swiper-button-next:after,
.swiper-button-prev:after,
.swiper-button:after {
  content: "";
  pointer-events: auto;
  width: 36px;
  height: 36px;
  min-width: 36px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 50%;
}
.swiper[data-v-9adf7346] {
  .swiper-button-prev {
    display: none;
  }
}
.swiper[data-v-9adf7346] {
  .swiper-button-next {
    display: none;
  }
}

.swiper[data-v-9adf7346] {
  .swiper-button-prev {
    &::after {
      content: "";
      transform: rotate(180deg);
      pointer-events: auto;
      width: 36px;
      height: 36px;
      min-width: 36px;
      border-radius: 50%;
      background-color: transparent;
      border: 1px solid #fff;
      background-image: url(../public/image/arrow-white.svg);
      background-repeat: no-repeat;
      background-position: 50%;
    }
    right: 210px;
    left: auto;
  }
}
.swiper[data-v-9adf7346] {
  .swiper-button-next {
    &::after {
      content: "";
      pointer-events: auto;
      width: 36px;
      height: 36px;
      transform: rotate(180deg);
      min-width: 36px;
      border-radius: 50%;
      background-color: transparent;
      border: 1px solid #fff;
      background-image: url(../public/image/arrow-white.svg);
      background-repeat: no-repeat;
      background-position: 50%;
    }
    right: 160px;
  }
}

@media (min-width: 1024px) {
  .swiper[data-v-9adf7346] {
    .swiper-button-next,
    .swiper-button-prev {
      display: flex;
      position: absolute;
      top: 25px;
    }
  }
}

@media (min-width: 1440px) {
  .swiper-button-prev {
    left: 92px;
  }
}
@media (min-width: 1024px) {
  .swiper-button-prev {
    display: flex;
    left: 75px;
  }
}

@media (min-width: 1440px) {
  .swiper-button-next {
    right: 92px;
  }
}
@media (min-width: 1024px) {
  .swiper-button-next {
    display: flex;
    right: 75px;
  }
}
