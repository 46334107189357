$loader-width: 100px;
$loader-height: 100px;
$loader-color-primary: rgb(240, 117, 35);
$loader-color-secondary: #eee;
$line-width: 4px;
$animation-duration: 1s;
$loader-initial-scale: 0.1;

.loader,
.loader:before,
.loader:after {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
}
/*
In order to get optimal results, please only change the 
variables above and don't change anything in the actual loader code
*/

@keyframes momentum {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}

.loader.quantum-spinner {
    --primary-circle-offset: calc(((#{$loader-width} * 0.2) / 2) - #{$line-width});
    --secondary-circle-offset: calc(
        ((#{$loader-width} * 0.4) / 2) - #{$line-width}
    ); /*- (var(--line-width,4px) * 2)*/
    position: relative;
    width: #{$loader-width};
    height: #{$loader-height};
    transform-origin: center center;
    border-radius: 50%;
    border: #{$line-width} solid rgba(0, 0, 0, 0);
    border-top-color: #{$loader-color-primary};
    animation: momentum #{$animation-duration} linear infinite;
}

.quantum-spinner:before {
    content: '';
    position: absolute;
    transform-origin: center center;
    top: var(--primary-circle-offset, 10px);
    left: var(--primary-circle-offset, 10px);
    width: calc(#{$loader-width} * 0.8);
    height: calc(#{$loader-height} * 0.8);
    border-radius: 50%;
    border: #{$line-width} solid rgba(0, 0, 0, 0);
    border-top-color: #{$loader-color-primary};
    opacity: 0.7;
    filter: hue-rotate(3eg);
    animation: momentum calc(#{$animation-duration} * 2) linear infinite;
}

.quantum-spinner:after {
    content: '';
    position: absolute;
    top: var(--secondary-circle-offset, 20px);
    left: var(--secondary-circle-offset, 20px);
    width: calc(#{$loader-width} * 0.6);
    height: calc(#{$loader-height} * 0.6);
    border-radius: 50%;
    transform-origin: center center;
    border: #{$line-width} solid rgba(0, 0, 0, 0);
    border-top-color: #{$loader-color-primary};
    opacity: 0.3;
    filter: hue-rotate(6eg);
    animation: momentum #{$animation-duration} linear infinite;
}
.modal {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.modalContent {
    border:none;
    background: transparent;
}
.backdrop {
    opacity: 0 !important;
}
