@font-face {
    font-display: swap;
    font-family: Neo Sans Pro;
    font-style: normal;
    font-weight: 700;
    src: url(./fonts/NeoSansPro-Bold.woff2) format('woff2');
}
@font-face {
    font-display: swap;
    font-family: Neo Sans Pro;
    font-style: normal;
    font-weight: 500;
    src: url(./fonts/NeoSansPro-Medium.woff2) format('woff2');
}
@font-face {
    font-display: swap;
    font-family: Neo Sans Pro;
    font-style: normal;
    font-weight: 400;
    src: url(./fonts/NeoSansPro-Regular.woff2) format('woff2');
}
